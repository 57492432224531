import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Main from "../modules/protected/Main";
import PatientVisit from "../containers/PatientVisit";
import Home from "../containers/Home";
import Patient from "../containers/Patient";
import PatientRegister from "../containers/PatientRegister";
import PatientUpdate from "../containers/PatientUpdate";
import Appointment from "../containers/Appointment";
import User from "../containers/User";
import ViewPatient from "../containers/ViewPatient";
import PatientAdmission from "../containers/PatientAdmission";
import PatientAdmissionList from "../containers/PatientAdmissionList";

const Index = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} >
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />          
          <Route path="patientvisit" element={<PatientVisit />} />
          <Route path="appointment" element={<Appointment/>} />
          <Route path="user" element={<User/>} />
          <Route path="patient" element={<Patient/>} />
          <Route path="patient/register" element={<PatientRegister/>} />
          <Route path="patient/edit/:id" element={<PatientUpdate/>} />      
          <Route path="patient/view/:id" element={<ViewPatient/>} />  
          <Route path="patient-admission-list" element={<PatientAdmissionList/>} />
          <Route path="patient-admission" element={<PatientAdmission/>} />  
        </Route>
      </Routes>
    </Router>
  );
};

export default Index;
