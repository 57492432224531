import React, { useState, useEffect } from "react";
import { useSnackbar } from 'react-simple-snackbar'
import { Link } from 'react-router-dom';

import axios from "../../utils/axios";
import Table from "../../components/Table";
import DeleteModal from "../../components/DeleteModal";
import useModal from "../../components/useModal";
import "../../assets/css/Style.css";

import AddPatient from "../../assets/images/addpatient.svg";
import Arrow1 from "../../assets/images/arrow1.svg"
import Search from "../../assets/images/search.svg"
import Edit from "../../assets/images/edit.svg"
import Delete from "../../assets/images/delete.svg"
import View from "../../assets/images/view.svg"

const Patient = () => {
    const options = {
        position: 'top-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center',
        },
    }
    const [value, setValue] = useState("");
    const [openSnackbar, closeSnackbar] = useSnackbar(options)
    
    useEffect(() => {
        axios.get('/prod/patients').then((response) => {
            setValue(response.data);
        });
    }, []);

    const SearchData = (values) => {
        axios
          .post("/prod/patientsearch", {"search_key":values,"limit": 10,"order_by" : "", "order_type":"DESC", "page":0})
          .then((response) => {
            setValue(response.data);
            console.log(values, value);
          })
          .catch(e => {
            console.log("error: ", e)
          });
        
      }


    const deleteData = (id) => {
        const list = [...value.results];
        const selected = list.find((results) =>
            results.id === id
        );
        list.splice(list.indexOf(selected), 1)
        axios
            .delete(`prod/patients/${id}`)
            .then((response) => {
                console.log(response)
                openSnackbar("Deleted Successfully")
                setTimeout(() => {
                    setValue({ results: list })
                }, 1000);
                
            });
    }

    const [inputdata, setInputdata] = useState('');

    const handleChange = event => {
        setInputdata(event.target.value);
    };

    const handleClick = event => {
        // event.preventDefault();
        SearchData(inputdata);
    };

    const {isShowing, toggle} = useModal();
    const columns = [
        {
            name: "Patients",
            selector: (row) => <Link to={`/patient/view/${row.id}`} state={row}>
            <p className="hover:underline">{row.name[0].given + ' ' + row.name[0].family}</p>
        </Link>,
            sortable: true,
        },
        {
            name: "Phone Number",
            selector: (row) => <a href="tel:{row.telecom[0].value}">{row.telecom[0].value}</a>,
            sortable: true,
        },
        {
            name: "Birth Date",
            selector: (row) => row.birthDate,
            sortable: true,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    <Link to={`/patient/view/${row.id}`} state={row}>
                        <button className="px-1">
                            <img className="h-3 w-4" src={View} />
                        </button>
                    </Link>
                    <Link to={`/patient/edit/${row.id}`} state={row}>
                        <button className="px-1">
                            <img className="h-3 w-4" src={Edit} />
                        </button>
                    </Link>
                    <button onClick={toggle}>
                        <img className="h-3 w-4" src={Delete} alt="delete"/>
                        <DeleteModal
                            isShowing={isShowing}
                            hide={toggle}
                            deleteData = {deleteData}
                            data = {row}
                        />
                    </button>
                </div>
            ),
        },
    ];
    return (
        <div className="bg-background h-auto">
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <span className="text-lg font-semibold px-2 py-2 text-col">
                        Home
                    </span>
                    <img src={Arrow1} />
                    <span className="text-black font-medium text-lg  font-roboto px-2 py-2">Patients</span>
                </div>
                <div className="flex flex-col p-2">
                    <Link to={'/patient/register'}>
                        <button>
                            <img src={AddPatient} className="w-72 pt-4" />
                        </button>
                    </Link>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <span className="text-3xl font-bold px-2 py-5 text-black">
                    Patient List
                </span>
                <div className="flex flex-col p-2">
                    <div className="pt-2 relative mx-auto text-col">
                        <input
                            className="shadow border-col bg-white h-11 w-72 px-5 pr-16 text-sm focus:outline-none"
                            type="search"
                            name="search"
                            onChange={handleChange}
                            placeholder="Search"
                        />
                        <button type="submit" className="absolute right-0 top-0 mt-6 mr-4" onClick={handleClick}>
                            <img src={Search} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                <Table rows={value.results} columns={columns} />
            </div>
        </div>

    );
};

export default Patient;
