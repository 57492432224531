import React,{useState} from "react";
// import { useFormik } from "formik";
// import * as Yup from 'yup';

import { TextField } from "../components/TextField";
// import { Text } from "../components/Text";
import { Select } from "../components/Select";
import Button from "../components/Button";
import RadioButton from "../components/RadioButton";
import AccordionLayout from '../components/AccordionLayout';

const Admission = () => {
    const [activeIndex, setActiveIndex] = useState("");

    return (
        <form className="w-full flex flex-col gap-y-4" >
            <div className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] p-6 flex flex-row items-center h-12">
                <span className="text-white text-base font-roboto">UHID:0237471234</span>
                <span className="text-white text-base font-roboto ml-20">IP NO:097668</span>
            </div>

            <div className="flex flex-col flex-wrap w-full">
                <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                    <div className="flex flex-col w-[49%] px-6">
                        <TextField
                            label="Admission Department"
                            id="department"
                            name="department"
                            type="text"
                            className="text-col font-roboto bg-gray gap-2 box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                        />
                    </div>

                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Unit"
                            id="unit"
                            name="unit"
                            type="text"
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                        />
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <Select
                            label="Doctors/consultants"
                            name="consultants"
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                        >
                            {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Select>
                    </div>

                    <div className="flex flex-col w-[49%]  px-6">
                        <Select
                            label="Operative procedures"
                            name="procedure"
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                        >
                            {/* {(Nationality || []).map(item => (
                                <option
                                    key={item.nationality}
                                    value={item.nationality}
                                >
                                    {item.nationality}
                                </option>
                            ))} */}
                        </Select>
                    </div>

                    <div className="flex flex-col w-[49%] px-6">
                        <TextField
                            label="Date of admission"
                            id="admissionDate"
                            name="admissionDate"
                            type="date"
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1 mr-14"
                        />
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <TextField
                            label="Time"
                            id="admissionTime"
                            name="admissionTime"
                            type="time"
                            className="text-col font-roboto bg-gray box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                        />
                    </div>

                    <div className="flex flex-col w-[49%]  px-6">
                        <Select
                            label="Ward Type"
                            name="wardType"
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                        >
                            {/* {(Education || []).map(item => (
                                <option
                                    key={item.value}
                                    value={item.value}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                        </Select>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6" >
                        <Select
                            label="Floor No"
                            name="floorNo"
                            className="text-col font-roboto bg-gray box-border border-graycol focus:outline-none h-10 w-full text-sm"
                        >
                            {/* {(Occupation || []).map(item => (
                                <option
                                    key={item.OccupationName}
                                    value={item.OccupationName}
                                >
                                    {item.OccupationName}
                                </option>
                            ))} */}
                        </Select>
                    </div>
                    <div className="flex flex-col w-[49%]  px-6">
                        <div className="grid grid-cols-c1 gap-[1%]">
                            <span className="flex flex-row text-col font-roboto text-sm">MLC:</span>
                            <div className="flex flex-row text-col font-roboto text-sm gap-x-8">
                                <RadioButton
                                    label="Yes"
                                    title="MLC"
                                // value={value}
                                // onChange={handleChange}
                                />
                                <RadioButton
                                    label="No"
                                // value={value}
                                // onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
           
           
            <div className='flex flex-col justify-center items-center'>
            <AccordionLayout 
            title="Declaration"
            index={1}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          >
             <div className="bg-gray ml-6 mr-6 h-[100%] pt-4">
                <span className="text-xl font-montserrat font-semibold p-4">DECLARATION</span>
                <p className="text-sm text-roboto font-normal text-col p-4">Above category has been opted by us and expenses involved in the course of treatment during admission have been explained to us.We agree to make all the payments before discharges as per rules of the institute.in case we change to higher category of bed,undertake to pay the charges as per higher category for the entire entire stay.Also we shall be responsible for whatever money or valuables we bring to this institute and keep the same entirely at our on risk.</p>
                <p className="text-sm text-roboto font-normal text-col p-4">I/We hereby give consent to Delhi Heart&Lung Institue.For carrying Treatment.... including investigations,medications and operation under any kind of Anaesthesia as my own risk.if presented to me </p>
                <div className="flex flex-col flex-wrap w-full">
                    <div className="flex flex-row flex-wrap  gap-y-3 gap-x-[2%]">
                        <div className="flex flex-col w-[49%] px-6">
                            <TextField
                                label="Name(Kin First)"
                                id="nameFirst"
                                name="nameFirst"
                                type="text"
                                className="text-col font-roboto bg-white gap-2 box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            />

                        </div>

                        <div className="flex flex-col w-[49%]  px-6">
                            <TextField
                                label="Name(Kin Second)"
                                id="nameSecond"
                                name="nameSecond"
                                type="text"
                                className="text-col font-roboto bg-white box-border border-graycol focus:outline-none h-10 w-full text-sm px-1"
                            />
                        </div>
                        <div className="flex flex-col w-[49%]  px-6">
                            <Select
                                label="Relation with patient"
                                name="relation1"
                                className="text-col font-roboto bg-white box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            >
                                {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                            </Select>
                        </div>

                        <div className="flex flex-col w-[49%]  px-6">
                            <Select
                                label="Relation with patient"
                                name="relation2"
                                className="text-col font-roboto bg-white box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            >
                                {/* {(Marriagestatus || []).map(item => (
                                <option
                                    key={item.lvalue}
                                    value={item.lvalue}
                                >
                                    {item.label}
                                </option>
                            ))} */}
                            </Select>
                        </div>
                        <div className="flex flex-col w-[49%] px-6">
                            <TextField
                                label="Telephone No"
                                id="telephone1"
                                name="telephone1"
                                type="text"
                                className="text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 w-full text-sm px-1 mr-14"
                            />
                        </div>
                        <div className="flex flex-col w-[49%]  px-6">
                            <TextField
                                label="Telephone No"
                                id="telephone2"
                                name="telephone2"
                                type="text"
                                className="text-col font-roboto bg-white box-border border-gray focus:outline-none h-10 w-full text-sm px-1"
                            />
                        </div>

                        <div className="flex flex-col w-[49%]  px-6">
                            <Select
                                label="Informant"
                                name="informant"
                                className="text-col font-roboto bg-white box-border border-graycol focus:outline-none h-10 w-full text-sm"
                            >
                                {/* {(Nationality || []).map(item => (
                                <option
                                    key={item.nationality}
                                    value={item.nationality}
                                >
                                    {item.nationality}
                                </option>
                            ))} */}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between p-4 mt-28">
                    <span className="text-sm font-roboto font-normal">Sign. of patient or Representative:</span>
                    <span className="text-sm font-roboto font-normal">Signatory's of relationship</span>
                    <span className="text-sm font-roboto font-normal">Sign. of admitting clerk</span>
                </div>
            </div>
          </AccordionLayout>
      </div>
           
           
           
           
           
           
           
           
           
           
           
           
            <div className="flex items-center justify-center">
                <div className="flex flex-row gap-4">
                    <Button
                        label="Save"
                        className="bg-gradient-to-t from-[#006E82] to-[#00a2b7] py-[0.5rem] px-[4rem] my-8 text-sm border rounded-md text-white h-12"
                    />
                    <Button
                        label="Cancel"
                        className="bg-gray border-graycol py-[0.5rem] px-[4rem] my-8 text-sm  border rounded-md text-col h-12"
                    />
                </div>
            </div>
        </form>
    )
}

export default Admission;
