import React from "react";

const User = () => {
  return (
    <div>
      <h1>This is the Users page</h1>
    </div>
  );
};

export default User;