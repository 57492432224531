import React from 'react';

const RadioButton = ({ label, value, onChange,title }) => {
    return (
        <label>
            <input type="radio" checked={value} onChange={onChange} />
            {label}
        </label>
    );
};

export default RadioButton;
