import React from "react";
import axios from "../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from 'react-simple-snackbar'

import PatientRegistration from "../../forms/Patient";
import Arrow1 from "../../assets/images/arrow1.svg";

const PatientRegister = () => {  
    const options = {
        position: 'top-center',
        style: {
            background: 'linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)',
            border: '#00A2B7',
            color: 'white',
            fontFamily: 'roboto',
            fontSize: '20px',
            textAlign: 'center',
        },
    }

 const [open, close] = useSnackbar(options)

 const navigate = useNavigate()

 const onAdd = (obj) => {
        prepareFormPacket(obj)
            .then(res =>
                axios.post('prod/patients', res)
                    .then((response) => {
                        open("created successfully")
                        setTimeout(() => {
                            navigate('/patient')
                            // window.location.href = "/patient"
                        }, 1000);
                    })
                    .catch(e => {
                        console.log("error: ", e)
                    })
            )
    }

    const prepareFormPacket = (obj) => {
        const stuctured_obj = {
            name: [
                {
                    given: obj.firstName,
                    family: obj.surName
                }
            ],
            active: true,
            gender: "male",
            maritalStatus: obj.maritalStatus,
            contact: [{
                name: [
                    {
                        given: obj.guardianName
                    }
                ]
            }],
            telecom: [
                {
                    value: obj.telecom
                }
            ],
            birthDate: obj.birthDate,
            address: [{
                use: '',
                type: 'home',
                text: '',
                line: [obj.resaddress1, obj.resaddress2],
                city: obj.city,
                district: obj.district,
                state: obj.state,
                postalCode: obj.postalCode,
                country: obj.country,
                period: {}
            }, {
                use: '',
                type: 'office',
                text: '',
                line: [obj.officeAddress1, obj.officeAddress2],
                city: obj.city1,
                district: obj.district1,
                state: obj.state1,
                postalCode: obj.postalCode1,
                country: obj.country1,
                period: {}
            }],
            extra_details: {
                religion: obj.religion,
                nationality: obj.nationality,
                education: obj.education,
                occupation: obj.occupation,
                bloodGrouping: obj.bloodGroup,
                BPLNo: obj.BPLNo,
                rationCard: obj.rationCard,
                annualIncome: obj.annualIncome,
                aadharNumber: obj.aadharNumber,
                village: obj.village,
                postOffice: obj.postOffice,
                policeStation: obj.policeStation,
                revenue: obj.revenue,
                village1: obj.village1,
                postOffice1: obj.postOffice1,
                policeStation1: obj.policeStation1,
                revenue1: obj.revenue1,
                age: ''
            }
        }
        return Promise.resolve(stuctured_obj)
    }

    return (
        <div className="bg-gray h-full">
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <Link to="/home">
                        <span className="text-lg font-semibold px-2 py-0.5 text-col font-roboto">
                            Home
                        </span>
                    </Link>
                    <img src={Arrow1} alt="arrow"/>
                    <span className="text-black font-medium text-lg px-2 py-0.5 font-roboto">Patient Registration Form</span>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                    Patient Registration Form
                </span>
            </div>
            <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                <PatientRegistration handleSubmit={onAdd} isDisabled={false} />
            </div>
        </div>
    )
}

export default PatientRegister;
