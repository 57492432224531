import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import arrow from "../../src/assets/images/arrow.svg";

const Sidebar = () => {
    const [open, setOpen] = useState(true);
    const[toggle,setToggle] = useState(true);

    const [isActive, setIsActive] = useState(null);
    const [isSelected, setIsSelected] = useState(null);

    const links = [
        { name: "Vitals", link: "" },
        { name: "Social History", link: "" },
        { name: "Surgical History", link: "" },
        { name: "Diagnosis", link: "" },
        { name: "Medication", link: "" },
    ];

    const link = [
        { name: "Registration", link: "/patient/register" },
        { name: "Admission", link: "" },
    ];
    const handleClick = (index) => {
        localStorage.setItem("selectedIndex", index);
        setIsActive(index);
    };
 
    const handleSelect = (index) => {
        localStorage.setItem("selectedIndex", index);
        setIsSelected(index);
    };

    useEffect(() => {
        setIsActive(localStorage.getItem('selectedIndex') ? Number(localStorage.getItem('selectedIndex')) : null);
    }, []);
    
    useEffect(() => {
        setIsSelected(localStorage.getItem('selectedIndex') ? Number(localStorage.getItem('selectedIndex')) : null);
    }, []);


    return (
        <div className="container">

            <Link to={'/home'}>
                <button
                    type="button"
                    className="border-b bg-primary items-center px-2 py-4 text-roboto  bg-primary w-full text-white font-normal"
                >
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <span className="ml-2">Home</span>
                        </div>
                        <div>
                            <img src={arrow} alt="right arrow"/>
                        </div>
                    </div>
                </button>
            </Link>
           
            <button
                type="button"
                className="border-b bg-primary items-center px-2 py-4 text-roboto bg-primary w-full text-white font-normal"
                onClick={() => setToggle(!toggle)}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Patient</span>
                    </div>
                    <div>
                        <img src={arrow} alt="arrow"/>
                    </div>
                </div>
            </button>
            {toggle && (
                <div className="dropdown-wrapper">
                    <ul className="dropdown-menu">
                        {link.map((menu, index) => (
                            <Link
                                key={index}
                                to={menu.link}
                            >
                                <li
                                    className={`${isActive === index ? 'bg-primary' : ''} flex items-center p-2 pl-11 border-b bg-primary shadow-2xl w-full text-white text-roboto font-normal hover:bg-primary no-underline`}
                                    onClick={() => handleSelect(index)}
                                >
                                    {menu.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )}

            <button
                type="button"
                className="border-b bg-primary items-center px-2 py-4 text-roboto  bg-primary w-full text-white font-normal"
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Appointment</span>
                    </div>
                    <div>
                        <img src={arrow} alt="arrow"/>
                    </div>
                </div>
            </button>

            <button
                type="button"
                className="border-b bg-primary items-center px-2 py-4 text-roboto bg-primary w-full text-white font-normal"
                onClick={() => setOpen(!open)}
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">Patient Visit</span>
                    </div>
                    <div>
                        <img src={arrow} alt="arrow"/>
                    </div>
                </div>
            </button>
            {open && (
                <div className="dropdown-wrapper">
                    <ul className="dropdown-menu">
                        {links.map((menu, index) => (
                            <Link
                                key={index}
                                to={menu.link}
                            >
                                <li
                                    className={`${isActive === index ? 'bg-primary' : ''} flex items-center p-2 pl-11 border-b bg-primary shadow-2xl w-full text-white text-roboto font-normal hover:bg-primary no-underline`}
                                    onClick={() => handleClick(index)}
                                >
                                    {menu.name}
                                </li>
                            </Link>
                        ))}
                    </ul>
                </div>
            )}


            <button
                type="button"
                className="border-b bg-primary items-center px-2 py-4  bg-primary w-full text-white font-normal"
            >
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <span className="ml-2">User Management</span>
                    </div>
                    <div>
                        <img src={arrow} alt="arrow"/>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default Sidebar;
