export const config = {
    api: {

        development:"https://ul9u7kfnej.execute-api.ap-south-1.amazonaws.com",
        qa: "https://lhueypqogf.execute-api.ap-south-1.amazonaws.com",
        production:"https://ul9u7kfnej.execute-api.ap-south-1.amazonaws.com"

    }    
}

