import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import axios from "../../utils/axios";
import Table from "../../components/Table";
import "../../assets/css/Style.css";

import AddPatient from "../../assets/images/addpatient.svg";
import Arrow1 from "../../assets/images/arrow1.svg"
import Search from "../../assets/images/search.svg"
import Edit from "../../assets/images/edit.svg"
import Delete from "../../assets/images/delete.svg"
import View from "../../assets/images/view.svg"
import PatientAd from "../../assets/images/patientadmission.svg"

const PatientAdmissionList = () => {
    const [value, setValue] = useState("");
    const [selectedObject, setSelectedObject] = useState("");

    const editHandler = (row) => {
        setSelectedObject(row)
        console.log(row)
    }

    useEffect(() => {
        axios.get('/prod/encounters').then((response) => {
            console.log(response)
            setValue(response.data);
        });
    }, []);

    const deleteData = (id) => {
        const list = [...value.results];
        const selected = list.find((results) =>
            results.id === id
        );
        list.splice(list.indexOf(selected), 1)
        axios
            .delete(`prod/patients/${id}`)
            .then((response) => {
                alert("Patient deleted!");
                console.log(response)
                setValue({ results: list })
            });
    }

    const columns = [
        {
            name: "Patients Name",
            sortable: true,
        },
        {
            name: "Phone Number",
            sortable: true,
        },
        {
            name: "Age",
            sortable: true,
        },
        {
            name: "Gender",
            sortable: true,
        },
        {
            name: "Type",
            sortable: true,
        },
        {
            name: "Reason",
            sortable: true,
        },
        {
            name: "No.of Days",
            sortable: true,
        },
        {
            name: "Actions",
            selector: (row) => (
                <div>
                    <button className="px-1"><img className="h-3 w-4" src={View} /></button>
                    <Link to={`/update/${row.id}`}>
                        <button className="px-1" onClick={() => editHandler(row)}><img className="h-3 w-4" src={Edit} /></button>
                    </Link>
                    <button onClick={() => deleteData(row.id)}><img className="h-3 w-4" src={Delete} /></button>
                </div>
            ),
        },
    ];
    return (
        <div className="bg-background h-auto">
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <span className="text-lg font-semibold px-2 py-2 text-col">
                        Home
                    </span>
                    <img src={Arrow1} />
                    <span className="text-black font-medium text-lg  font-roboto px-2 py-2">Admission List</span>
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col p-2">
                        <Link to={'/register'}>
                            <button>
                                <img src={AddPatient} className="w-64 pt-4" />
                            </button>
                        </Link>
                    </div>
                    <div className="flex flex-col p-2">
                        <Link to={'/patient-admission'}>
                            <button>
                                <img src={PatientAd} className="w-64 pt-4" />
                            </button>
                        </Link>
                    </div>
                </div>

            </div>
            <div className="flex flex-row justify-between">
                <span className="text-3xl font-bold font-mont px-2 py-5 text-black">
                    Admission List
                </span>
                <div className="flex flex-col p-2">
                    <div className="pt-2 relative mx-auto text-col">
                        <input
                            className="shadow border-col bg-white h-11 w-64 px-5 pr-16 text-sm focus:outline-none"
                            type="search"
                            name="search"
                            placeholder="Search"
                        />
                        <button type="submit" className="absolute right-0 top-0 mt-6 mr-4">
                            <img src={Search} />
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-white rounded p-6 ml-2 mr-2 mb-4 flex flex-col justify-between">
                <Table rows={value.results} columns={columns} />
            </div>
        </div>

    );
};

export default PatientAdmissionList;
