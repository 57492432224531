
import Index from './pages/index.js';
import SnackbarProvider from 'react-simple-snackbar'

function App() {
  return (
    <div className="App">
      <SnackbarProvider>
        <Index />
      </SnackbarProvider>
    </div>
  );
}

export default App;
