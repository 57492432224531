import React from "react";
import { Link } from 'react-router-dom';

import SideNavbar from "../../containers/SideNavbar";

import PatientIcon from "../../assets/images/patienticon.svg"
import AppointmentIcon from "../../assets/images/appointmenticon.svg"
import PatientVisitIcon from "../../assets/images/patientvisiticon.svg"
import UserManagement from "../../assets/images/usermanagementicon.svg"

const Home = () => {
    return (
        <div>
            <div className="flex flex-row bg-homebackground">
                <SideNavbar />
                <div className="overflow-hidden flex flex-col flex-wrap w-full md:w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pt-10 justify-around">
                        <div>
                            <Link to="/patient">
                                <button>
                                    <div className="flex flex-col">
                                        <div className="box-border rounded-full w-48  h-48 border ml-12 border-blue p-4">
                                            <div className="h-40 w-40 border border-blue box-border rounded-full bg-gradient-to-t from-[#006E82] to-[#00a2b7]">
                                                <div><img className="p-14" src={PatientIcon} alt="patient"/></div>
                                            </div>
                                        </div>
                                        <span className="font-robototo text-lg text-bold mt-4 ml-12">Patient</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div>
                            <Link to="">
                                <button>
                                    <div className="flex flex-col">
                                        <div className="box-border rounded-full h-48 w-48 border ml-12 border-blue p-4">
                                            <div className="h-40 w-40 border border-blue box-border rounded-full bg-gradient-to-t from-[#006E82] to-[#00a2b7]">
                                                <div><img className="p-14" src={AppointmentIcon} alt="appointment"/></div>
                                            </div>
                                        </div>
                                        <span className="font-robototo text-lg text-bold mt-4 ml-12">Appointment</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div>
                            <Link to="">
                                <button>
                                    <div className="flex flex-col">
                                        <div className="box-border rounded-full h-48 w-48 border ml-12 border-blue p-4">
                                            <div className="h-40 w-40 border border-blue box-border rounded-full bg-gradient-to-t from-[#006E82] to-[#00a2b7]">
                                                <div><img className="p-14" src={PatientVisitIcon} alt="patientvisit"/></div>
                                            </div>
                                        </div>
                                        <span className="font-robototo text-lg text-bold mt-4 text-center ml-12">PatientVisit</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                        <div>
                            <Link to="">
                                <button>
                                    <div className="flex flex-col">
                                        <div className="box-border rounded-full h-48 w-48 border ml-12 border-blue p-4">
                                            <div className="h-40 w-40 border border-blue box-border rounded-full bg-gradient-to-t from-[#006E82] to-[#00a2b7]">
                                                <div><img className="p-14" src={UserManagement} alt="user"/></div>
                                            </div>
                                        </div>
                                        <span className="font-robototo text-lg text-bold mt-4 ml-12">User Management</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
