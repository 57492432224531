import React from "react";

import PatientRegistration from "../../forms/Patient";
import Arrow1 from "../../assets/images/arrow1.svg"
import { useLocation, Link } from "react-router-dom";

const ViewPatient = (props) => {
  
    const location = useLocation();

    return (
        
        <div className="bg-gray h-full">           
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <Link to="/home">
                        <span className="text-lg font-semibold px-2 py-0.5 text-col font-roboto">
                            Home
                        </span>
                    </Link>
                    <img src={Arrow1} alt="image"/>
                    <span className="text-black font-medium text-lg px-2 py-0.5 font-roboto">Patient Registration Form</span>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                    Patient Registration Form
                </span>
            </div>
            <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
                <PatientRegistration editData={location.state} isDisabled={true} style={{
                                backgroundColor:'white',
                                                
                              }}/>
            </div>
        </div>
    )
}

export default ViewPatient;
