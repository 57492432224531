import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../../containers/Header";

const Main = () => {
  return (
    <div className="w-full flex flex-col">
      <Header />
      <div className="bg-background" >
        <Outlet />
      </div>
    </div>
  );
};

export default Main;
