
import React from 'react';
import DataTable from 'react-data-table-component';

const customStyles = {
    headCells: {
        style: {
            background: "linear-gradient(178.36deg, rgb(0, 162, 183) 1.4%, rgba(0, 0, 0, 0.9) 300.49%)",
            color: "white",
            fontSize:"16px"
        },
    },
    cells: {
        style: {
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        },
    }
};

const Table = (props) => {
    return (
        <>
            <DataTable
                columns={props.columns}
                data={props.rows}
                customStyles={customStyles}
                pagination />
        </>
    )
};

export default Table;

