import React from 'react';
import arrow from "../../src/assets/images/arrow.svg";
import rightarrow from "../../src/assets/images/rightarrow.svg"

const AccordionLayout = ({ title, children, index, activeIndex, setActiveIndex }) => {
  const handleSetIndex = (index) => (activeIndex !== index) && setActiveIndex(index);

  return (
    <>
        <div onClick={() => handleSetIndex(index)} className='flex w-full h-12 justify-between p-2 pl-6 mt-2  bg-gradient-to-t from-[#006E82] to-[#00a2b7]'>
            <div className='flex'>
                <div className='text-white text-base font-roboto'>{title}</div>
            </div>
            <div className="flex items-center justify-center">
                {
                (activeIndex === index) 
                ?  <img src={arrow} alt="down arrow"/>
                :  <img src={rightarrow} alt="right arrow"/>
                }
            </div>
        </div>

        {(activeIndex === index) && (
            <div className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
              {children}
            </div>
        )}
    </>
  );
};

export default AccordionLayout;
