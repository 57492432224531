
import React from "react";
import { Link } from 'react-router-dom';

import profile from "../../assets/images/profile.svg";
import hospital from "../../assets/images/vagus-logo.png";

const Header = () => {
  return (
    <nav className="relative w-full flex flex-row flex-wrap items-center justify-between pr-2 py-3 bg-white-100">
      <div className="flex items-center pl-2 w-36">
        <Link to="/home">
          <img src={hospital} alt="logo"/> 
        </Link> 
      </div>
      <div className="flex items-center space-x-2">
        <div className="p-4 rounded-full bg-white shadow-lg">
          <img src={profile} />
        </div>
      </div>
    </nav>
  );
};

export default Header;

