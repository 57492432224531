import React from "react";

const Appointment = () => {
  return (
    <div>
      <h1>This is the Appointment page</h1>
    </div>
  );
};

export default Appointment;
