import React from "react";

import Sidebar from "../../components/Sidebar";

const SideNavbar = () => {
  return (
    <div>
      <aside
        className="min-h-screen w-[12.5rem] bg-[#0D8393]"
        aria-label="Sidebar"
      >
        <div className=" pb-4 rounded">
          <ul>
            <li className="border-b border-bg-[#0D8393]">
              <Sidebar />
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default SideNavbar;
