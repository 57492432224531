import React from "react";
import Admission from "../../forms/Admission";
import Arrow1 from "../../assets/images/arrow1.svg"

const PatientAdmission = () => { 
    return (
        <div className="bg-gray h-full">
            <div className="flex flex-row justify-between ">
                <div className="flex items-center">
                    <span className="text-lg font-semibold px-2 py-0.5 text-col font-roboto">
                        Home
                    </span>
                    <img src={Arrow1} />
                    <span className="text-black font-medium text-lg px-2 py-0.5 font-roboto">Patient Admission Form</span>
                </div>
            </div>
            <div className="flex flex-row justify-between">
                <span className="text-2xl font-bold font-montserrat mx-auto pb-2 text-black ">
                    Patient Admission Form
                </span>
            </div>
            <div className="bg-white ml-6 mr-6 flex flex-row justify-between">
             <Admission />
            </div>
        </div>
    )
}

export default PatientAdmission;
